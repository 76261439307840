<template>
  <div class="card card-ecart ">
    <div class="card-header">
      <div class="row justify-content-between align-items-center">
        <div class="col-6 col-md-6">
          <h5 class="" v-text="$t('dashboard.dashboard.titles.sales')"></h5>
        </div>
        <div class="col-4 col-md-2 form-rounded-inputs">
          <b-dropdown variant="outline-light text-white " size="sm" block menu-class="w-100" right :text="$t('dates.days')">
            <b-dropdown-item @click="fnApiGetChart(option)" :value="option" v-for="(option,index) in options"
              :key="index">
              <span v-text="option + ' ' + $t('dates.days') "></span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="card-body ">
      <div id="chart" class="chart-container animated fadeIn" v-show="!loading.chart && charData.length > 0 "></div>
      <no-data :dataMessage="$t('noDataMessages.noInformaton')" sm v-show=" loading.chart || charData.length == 0"
        :isLoading="loading.chart" :showBtn="false">
      </no-data>

    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  import ApexCharts from 'apexcharts';


  export default {
    computed: {
      ...mapState('dashboard', ['charData', 'loading']),
    },
    data() {
      return ({
        chartContainer: null,
        daysRange: 90,
        options: [7, 30, 90, 360],
      })
    },
    watch: {
      charData() {
        if (this.chartContainer) {
          this.chartContainer.destroy();
        }
        this.fnInitChart();
      }
    },
    methods: {
      ...mapActions('dashboard', ['fnApiGetChart']),
      fnInitChart() {
        let tempData = [];
        let maxSales = 1;
        this.charData.map(data => {
          if (data.sales > maxSales) {
            maxSales = data.sales;
          }
          tempData.push({
            y: data.sales,
            x: this.$moment(data.id, 'DD-MM-YYYY').format(),
          });
        })
        var options = {
          colors: ['#81b23a'],
          dataLabels: {
            enabled: true,
          },
          chart: {
            type: 'bar',
            height: '300px',
            fillTo: 'origin',
          },
          yaxis: {
            forceNiceScale: true,
            min: 0,
            max: (maxSales + 2 ),
            },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          series: [{
            name: this.$t('dashboard.dashboard.titles.sales'),
            data: tempData
          }],
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'dd/MM/yyyy',
            }
          },
          dataLabels: {
              enabled: false,
            },
          tooltip: {
            x: {
              show: true,
              format: 'dd/MM/yyyy',
            },
          }
        }
        this.chartContainer = new ApexCharts(document.querySelector("#chart"), options);
        this.chartContainer.render();
      },
    },
    mounted() {
      this.fnApiGetChart(this.daysRange);
    },
    beforeDestroy() {
      if (this.chartContainer != null) {
        this.chartContainer.destroy();
      }
    }
  }
</script>

<style>
</style>